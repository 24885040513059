import { lazy, useMemo } from "react";
const DownloadRoutable = lazy(
  () => import("./download-routable-DsFIdssg.js").then((module) => ({
    default: module.DownloadRoutable
  }))
);
const useDownloadRoutable = () => {
  return useMemo(
    () => ({
      download: {
        component: DownloadRoutable
      }
    }),
    []
  );
};
export {
  useDownloadRoutable
};
